import React from 'react';
import { ComingSoon } from './ComingSoon';

function App() {
  return (
    <ComingSoon
      imageSrc={'rival-gravity-0xfff.png'}
      altText={'RivalGravity | coming soon...'}
    />
  );
}

export default App;
