
import { Center, Image, Paper } from '@mantine/core';

export interface ComingSoonProps {
  imageSrc: string;
  altText: string;
}

export const ComingSoon: React.FC<ComingSoonProps> = ({ imageSrc, altText }) => (
    <Paper>
      <Center maw={800} h={800} mx="auto">
          <Image src={imageSrc} alt={altText} maw={300}/>
      </Center>
    </Paper>
);